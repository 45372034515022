import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { get, isEqual } from 'lodash';

// Selectors
import {
  selectSidebarDefinition,
  selectUsePopup,
  selectUsePopupSidebar,
} from 'app/modules/sidebar/selectors';

// Models
import {
  SidebarComponentTypes,
  SidebarDefinition,
} from 'app/modules/sidebar/models';

// Components
import { FeatureFlagControl } from 'app/modules/devtools/components/FeatureFlagControl';
import { PermissionsControl } from 'app/modules/devtools/components/PermissionControl';
import { SidebarEntity } from 'app/modules/sidebar/components/SidebarEntity';
import { SidebarInstrument } from 'app/modules/sidebar/components/SidebarInstrument';
import { SidebarActionEvent } from 'app/modules/sidebar/components/SidebarActionEvent';
import { SidebarTableConfig } from 'app/modules/sidebar/components/SidebarTableConfig';
import { SidebarTransaction } from 'app/modules/sidebar/components/SidebarTransaction';
import { SidebarRuleAdmin } from 'app/modules/rulesAdmin/components/sidebar/SidebarRuleAdmin';
import { ProcessingErrorsSidebar } from 'app/modules/pullBasedDataFiles/components/sidebars/ProcessingErrorsSidebar';
import { DataSettingSidebar } from 'app/modules/dataSettings/components/DataSettingSidebar';
import { SidebarFincenCtr } from 'app/modules/sidebar/components/SidebarFincenCtr';
import { hideSidebar } from 'app/modules/sidebar/slice';

// Analytics
import { heapTrack } from 'app/shared/utils/heap';
import heapEvents from 'app/shared/utils/heapEvents';
import { SidebarFinCENCTRCustomEntity } from 'app/modules/sidebar/components/SidebarFinCENCTRCustomEntity';
import { SidebarAlert } from 'app/modules/sidebar/components/SidebarAlert';
import { SidebarCase } from 'app/modules/sidebar/components/SidebarCase';
import { SidebarFinCENSAR } from 'app/modules/sidebar/components/SidebarFinCENSAR';
import { createPortal } from 'react-dom';
import { IconGripHorizontal, IconX } from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components';
import Draggable from 'react-draggable';

import {
  POPUP_HEIGHT_PERCENT,
  POPUP_WIDTH,
} from 'app/modules/sidebar/constants';
import { consoleError } from 'app/shared/utils/console';
import { SidebarAiAdmin } from 'app/modules/aiAdmin/sidebar';
import { SidebarAIAgent } from 'app/modules/aiAgentResults/components/SidebarAIAgent';

interface StyleProps {
  $open?: boolean;
}

function usePreviousDefinition(
  lastDefinition: SidebarDefinition | undefined,
): SidebarDefinition | undefined {
  const ref = useRef<SidebarDefinition | undefined>(lastDefinition);

  useEffect(() => {
    ref.current = lastDefinition;
  }, [lastDefinition]);

  return ref.current;
}

export function Sidebar() {
  const sidebarDefinition = useSelector(selectSidebarDefinition);
  const prevDefinition = usePreviousDefinition(sidebarDefinition);
  const usePopupSidebar = useSelector(selectUsePopupSidebar);

  const location = useLocation();
  const dispatch = useDispatch();

  const content = useMemo(() => {
    switch (sidebarDefinition?.type) {
      case SidebarComponentTypes.ALERT:
        return <SidebarAlert data={sidebarDefinition.data} />;
      case SidebarComponentTypes.ENTITY:
        return <SidebarEntity data={sidebarDefinition.data} />;
      case SidebarComponentTypes.INSTRUMENT:
        return <SidebarInstrument data={sidebarDefinition.data} />;
      case SidebarComponentTypes.TRANSACTION:
        return <SidebarTransaction data={sidebarDefinition.data} />;
      case SidebarComponentTypes.ACTION_EVENT:
        return <SidebarActionEvent data={sidebarDefinition.data} />;
      case SidebarComponentTypes.TABLE_CONFIG:
        return <SidebarTableConfig data={sidebarDefinition.data} />;
      case SidebarComponentTypes.CASE:
        return <SidebarCase data={sidebarDefinition.data} />;
      case SidebarComponentTypes.SAR:
        return <SidebarFinCENSAR data={sidebarDefinition.data} />;
      case SidebarComponentTypes.FILINGS_BATCH:
        return <SidebarFincenCtr data={sidebarDefinition.data} />;
      case SidebarComponentTypes.RULE_ADMIN:
        return <SidebarRuleAdmin data={sidebarDefinition.data} />;
      case SidebarComponentTypes.PERMISSIONS:
        return <PermissionsControl />;
      case SidebarComponentTypes.FEATURE_FLAGS:
        return <FeatureFlagControl />;
      case SidebarComponentTypes.FFIP_VIEW_ERRORS:
        return <ProcessingErrorsSidebar />;
      case SidebarComponentTypes.CREATE_EDIT_DATA_SETTING:
        return <DataSettingSidebar />;
      case SidebarComponentTypes.CTR_ENTITY_CUSTOM_DATA:
        return <SidebarFinCENCTRCustomEntity data={sidebarDefinition.data} />;
      case SidebarComponentTypes.AI_ADMIN:
        return <SidebarAiAdmin data={sidebarDefinition.data} />;
      case SidebarComponentTypes.AI_AGENT:
        return <SidebarAIAgent data={sidebarDefinition.data} />;
      default:
        return null;
    }
  }, [sidebarDefinition]);

  useEffect(() => {
    if (sidebarDefinition?.type === SidebarComponentTypes.TABLE_CONFIG) {
      dispatch(hideSidebar());
    }
    // Avoid triggering "hide" on "type" change after opening the sidebar
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (
      sidebarDefinition?.type !== prevDefinition?.type ||
      !isEqual(sidebarDefinition?.data, prevDefinition?.data)
    ) {
      // Open is true when we get a new sidebar type that is not undefined
      const open = sidebarDefinition?.type !== undefined;
      heapTrack(heapEvents.sidebar.toggle, {
        // We use the prevType if the sidebar is closed (new one is undefined)
        type: open ? sidebarDefinition.type : prevDefinition?.type,
        open,
        // Try to get the sidebar id (should exist on object sidebars like alerts, entities, cases, etc)
        id: get(sidebarDefinition?.data, 'id'),
      });
    }
  }, [
    sidebarDefinition?.type,
    sidebarDefinition?.data,
    prevDefinition?.type,
    prevDefinition?.data,
  ]);

  const popupRef = useRef<HTMLDivElement>(null);

  // don't need live state so use ref
  const isDraggingRef = useRef(false);

  // calculate default coordinates so the popup stays on screen
  const defaultPosition = useMemo(() => {
    if (!sidebarDefinition?.coordinates) {
      return { x: 0, y: 0 };
    }
    const { x: clickX, y: clickY } = sidebarDefinition.coordinates;
    const { innerWidth, innerHeight } = window;

    const popupWidth = POPUP_WIDTH;
    let x = clickX + popupWidth <= innerWidth ? clickX : clickX - popupWidth;
    if (x < 0) {
      x = 0;
    }

    const popupHeight = POPUP_HEIGHT_PERCENT * innerHeight;
    // target the middle of popup as the click y coordinate
    let y =
      clickY + popupHeight / 2 <= innerHeight
        ? clickY - popupHeight / 2
        : innerHeight - popupHeight;
    if (y < 0) {
      y = 0;
    }

    return { x, y };
  }, [sidebarDefinition?.coordinates]);

  // notify if we missed a sidebar
  const usePopup = useSelector(selectUsePopup);
  useEffect(() => {
    if (sidebarDefinition && usePopup && !sidebarDefinition.coordinates) {
      if (
        sidebarDefinition.type !== SidebarComponentTypes.FEATURE_FLAGS &&
        sidebarDefinition.type !== SidebarComponentTypes.PERMISSIONS
      ) {
        consoleError(
          'Missing popup sidebar coordinates',
          sidebarDefinition.type,
        );
      }
    }
  }, [usePopup, sidebarDefinition]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (usePopupSidebar) {
      const onKeyPress = (e: KeyboardEvent) => {
        if (
          e.target instanceof HTMLElement &&
          e.target.nodeName === 'BODY' &&
          e.key === 'Escape'
        ) {
          dispatch(hideSidebar());
        }
      };
      window.addEventListener('keyup', onKeyPress);
      return () => {
        window.removeEventListener('keyup', onKeyPress);
      };
    }
  }, [dispatch, usePopupSidebar]);

  if (usePopupSidebar) {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        // prevent highlighting the content below the popup
        onMouseDown={(e) => {
          if (isDraggingRef.current) {
            e.preventDefault();
          }
        }}
      >
        {createPortal(
          <Draggable
            // create a new instance if sidebar definition changes
            key={JSON.stringify(sidebarDefinition)}
            bounds="body"
            defaultPosition={defaultPosition}
            handle={String(DraggableArea)}
            nodeRef={popupRef}
            onStop={() => {
              isDraggingRef.current = false;
            }}
            onStart={() => {
              isDraggingRef.current = true;
            }}
          >
            <PopupContainer ref={popupRef}>
              <DraggableArea>
                <StyledIconGripHorizontal />
                <CloseButton
                  aria-label="Close"
                  onClick={() => dispatch(hideSidebar())}
                  icon={<IconX />}
                  size="small"
                />
              </DraggableArea>
              <Content>{content}</Content>
            </PopupContainer>
          </Draggable>,
          document.body,
        )}
      </div>
    );
  }
  return <Container $open={Boolean(content)}>{content}</Container>;
}

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  height: ${POPUP_HEIGHT_PERCENT * 100}vh;
  width: ${POPUP_WIDTH}px;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: ${(props) => props.theme.shape.borderRadiusMd}px;
  box-shadow: ${(props) => props.theme.customShadows.z24};

  // number chosen based on https://mui.com/material-ui/customization/z-index
  z-index: 1350;
`;

const DraggableArea = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  cursor: move;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

const StyledIconGripHorizontal = styled(IconGripHorizontal)`
  color: ${(props) => props.theme.palette.text.secondary};
`;

const CloseButton = styled(U21Button)`
  position: absolute;
  right: 16px; // same as DraggableArea padding

  // negative margin the padding amount
  margin: -5px;
`;

const Content = styled.div`
  flex: 1;
`;

const Container = styled.div<StyleProps>`
  grid-area: RightSlider;
  border-left: 0 solid ${(props) => props.theme.palette.divider};
  background: ${(props) => props.theme.palette.background.paper};
  transition: ${(props) => props.theme.transitions.create(['all'])};

  ${(props) =>
    props.$open
      ? css<StyleProps>`
          min-width: 370px;
          width: 370px;
          visibility: visible;
          border-left-width: 1px;
        `
      : css`
          min-width: 0;
          width: 0;
          visibility: hidden;
          border-left-width: 0;
        `}
`;
